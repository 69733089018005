import React, { useState, useEffect } from "react";
import axios from "axios";

const App = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(process.env.REACT_APP_ENDPOINT, {
        headers: { "X-API-KEY": process.env.REACT_APP_API_KEY },
      });
      setItems(result.data.contents);
    };
    fetchData();
  }, []);

  const renderItemFunction = (itemFunction) => {
    // 文字列を `\n` で分割し、各部分を <span> 要素にラップして配列に格納
    // 配列の各要素の間に <br /> を挿入
    return itemFunction.split("\n").map((part, index, array) => (
      <React.Fragment key={index}>
        <span>{part}</span>
        {index < array.length - 1 && <br />} {/* 最後の要素以外の後ろに <br /> を挿入 */}
      </React.Fragment>
    ));
  };

  return (
    <div>
      <div className="navbar bg-accent/80 sticky top-0 z-10">
        <a href="/" className="btn btn-ghost text-xl">
          風来のシレン6 杖一覧
        </a>
      </div>

      <div className="mx-auto container">
        <div className="overflow-x-auto">
          <table className="table table-zebra">
            <thead>
              <tr>
                <th>名前</th>
                <th>効果</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <th>{item.name}</th>
                  <td>{renderItemFunction(item.function)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default App;
